import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import PartialPageImgHeader from "../components/PartialPageImgHeader";
import TitleXL from "../components/TitleXL";
import InhaltsText from "../components/InhaltsText";
import Wrapper from "../components/Wrapper";
import HorizontalWrapper from "../components/HorizontalWrapper";
import { Helmet } from "react-helmet";

interface ArchivQueryProps {
  data: {
    cms: {
      entry: {
        title: string;
        uri: string;
        inhaltstext: string;
        parent: {
          mainImage: [{ url: string }];
        };
      };
    };
  };
}

export const archivQuery = graphql`
  query($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        uri
        ... on CMS_inhalt_archiv_Entry {
          inhaltstext
        }
        parent {
          ... on CMS_inhalt_fachbereich_Entry {
            mainImage {
              url
            }
          }
        }
      }
    }
  }
`;

const Archiv: React.FC<ArchivQueryProps> = ({ data }) => {
  const entry = data.cms.entry;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.parent.mainImage[0].url} />
      <HorizontalWrapper>
        <TitleXL spacingTop>{entry.title}</TitleXL>
      </HorizontalWrapper>
      <Wrapper>
        <InhaltsText html={entry.inhaltstext} />
      </Wrapper>
    </DefaultLayout>
  );
};

export default Archiv;
